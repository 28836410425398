import axios from "../../axios-auth";
import router from "../../router";
import Vue from "vue";

const state = {
  doctors: [],
  doctor: null,
  unassignedDoctors: [],
  assignedDoctors: [],
  eventDoctor: null,
};

const mutations = {
  SET_UNASSIGNED_DOCTORS(state, payload) {
    state.unassignedDoctors = payload;
  },
  SET_ASSIGNED_DOCTORS(state, payload) {
    state.assignedDoctors = payload;
  },
  SET_DOCTOR(state, payload) {
    state.doctor = payload;
  },
  SET_EVENT_DOCTOR(state, payload) {
    state.eventDoctor = payload;
  },
};

const actions = {
  async saveDoctor({ commit, state, dispatch, rootGetters }, payload) {
    axios
      .post("/doctors", payload.data.doctor, {
        headers: {
          Authorization: "Bearer " + rootGetters.token,
        },
      })
      .then((res) => {
        const msg = "You successfully saved a new doctor";
        const toast = {
          vm: payload.vm,
          msg: msg,
          color: "success",
        };
        // payload.vm.resetFields();
        payload.vm.loading = false;
        commit("makeToast", toast);
        if (payload.data.byEvent) {
          commit("SET_DOCTOR", res.data);

          const doctorData = {
            id: res.data.id,
          };
          dispatch("assigDoctorToEvent", {
            doctorData,
            eventId: router.currentRoute.params.id,
          }).then((data) => {
            const event = payload.event;

            if (event.hasPrintForm && !data.formPrinted) {
              router.push({
                name: "Print Form",
                params: {
                  eventId: router.currentRoute.params.id,
                  id: state.doctor.id,
                },
              });
            } else if (event.hasQuestionnaire && !data.questionnaireSubmitted) {
              router.push({
                name: "Questionnaire",
                params: {
                  eventId: router.currentRoute.params.id,
                  doctorId: state.doctor.id,
                },
              });
            } else if (event.hasPhoto && !data.photoPrinted) {
              router.push({
                name: "Select Frame",
                params: {
                  doctorId: state.doctor.id,
                  eventId: router.currentRoute.params.id,
                },
              });
            } else if (event.hasVoucher && !data.voucherPrinted) {
              payload.vm.$bvModal.show("voucher-modal");
            } else {
              router.push({ name: "Thank You" });
            }
          });
        }
      })
      .catch((err) => {
        const msg = "There was an error saving your doctor";
        payload.vm.loading = false;
        const toast = {
          vm: payload.vm,
          msg: msg,
          color: "danger",
        };

        commit("makeToast", toast);
      });
  },
  async assigDoctorToEvent({ commit, dispatch, rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/events/" + payload.eventId + "/doctors", payload.doctorData, {
          headers: { Authorization: "Bearer " + rootGetters.token },
        })
        .then((res) => {
          commit("SET_EVENT_DOCTOR", res.data);
          dispatch("getUnassignedDoctors", { eventId: payload.eventId });
          dispatch("getAssignedDoctors", { eventId: payload.eventId });
          dispatch(
            "doctorExistInEvent",
            {
              eventId: payload.eventId,
              doctorId: payload.doctorData.id,
            },
            { root: true }
          );
          resolve(res.data);
        })
        .catch((err) => {});
    });
  },
  getUnassignedDoctors({ commit, dispatch, rootGetters }, payload) {
    axios
      .get("/doctors", {
        headers: { Authorization: "Bearer " + rootGetters.token },
      })
      .then((res1) => {
        axios
          .get("/events/" + payload.eventId + "/doctors/assigned", {
            headers: { Authorization: "Bearer " + rootGetters.token },
          })
          .then((res2) => {
            const assigned = res2.data;
            const doctors = res1.data;

            let result = doctors.filter(
              (o1) => !assigned.some((o2) => o1.id === o2.doctorId)
            );

            commit("SET_UNASSIGNED_DOCTORS", result);
          })
          .catch((err) => {});
      })
      .catch((err) => {});
  },
  getAssignedDoctors({ commit, rootGetters }, payload) {
    axios
      .get("/events/" + payload.eventId + "/doctors/assigned", {
        headers: { Authorization: "Bearer " + rootGetters.token },
      })
      .then((res) => {
        // console.log("RES.DATA", res.data);
        let assignedDoctors = res.data;
        if (Object.keys(payload).includes("report")) {
          assignedDoctors.unshift({ name: "Select All", doctorId: "all" });
        }
        commit("SET_ASSIGNED_DOCTORS", assignedDoctors);
      })
      .catch((err) => {});
  },
  getAssignedDoctorsForMultipleEvents({ commit, rootGetters }, payload) {
    // Extract event IDs from the payload
    const eventIds = payload.eventIds;
    const includeReport = payload.report || false;

    // Use Promise.all to fetch assigned doctors for all events in parallel
    Promise.all(
      eventIds.map((eventId) =>
        axios
          .get(`/events/${eventId}/doctors/assigned`, {
            headers: { Authorization: "Bearer " + rootGetters.token },
          })
          .then((res) => {
            return res.data.map((doctor) => ({
              ...doctor,
              eventId, // Add eventId to each doctor object
            }));
          }) // Extract the response data
          .catch((err) => {
            // console.error(`Failed to fetch doctors for event ${eventId}`, err);
            return []; // Return an empty array in case of an error
          })
      )
    )
      .then((results) => {
        // Combine all assigned doctors into a single list
        let combinedDoctors = [].concat(...results);

        // Remove duplicates based on doctorId using a Set
        let uniqueDoctors = [];
        let seenDoctorIds = new Set();

        combinedDoctors.forEach((doctor) => {
          if (!seenDoctorIds.has(doctor.doctorId)) {
            uniqueDoctors.push(doctor);
            seenDoctorIds.add(doctor.doctorId);
          }
        });

        // If the "Select All" option is required
        if (includeReport) {
          uniqueDoctors.unshift({ name: "Select All", doctorId: "all" });
        }

        // Commit the unique list to Vuex
        commit("SET_ASSIGNED_DOCTORS", uniqueDoctors);
      })
      .catch((err) => {
        // console.error(
        //   "Error fetching assigned doctors for multiple events",
        //   err
        // );
      });
  },
  getDoctors({ commit, rootGetters }) {
    axios
      .get("/doctors", {
        headers: { Authorization: "Bearer " + rootGetters.token },
      })
      .then((res) => commit("SET_DOCTORS", res.data))
      .catch((err) => {});
  },
  getDoctor({ commit, rootGetters }, payload) {
    axios
      .get("/doctors/" + payload.id, {
        headers: {
          Authorization: "Bearer " + rootGetters.token,
        },
      })
      .then((res) => {
        commit("SET_DOCTOR", res.data);
      })
      .catch((err) => {});
  },
};

const getters = {
  doctor(state) {
    return state.doctor;
  },
  doctors(state) {
    return state.doctors;
  },
  unassignedDoctors(state) {
    return state.unassignedDoctors;
  },
  assignedDoctors(state) {
    return state.assignedDoctors;
  },
  eventDoctor(state) {
    return state.eventDoctor;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
